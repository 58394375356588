import React from "react";
import { Helmet } from "react-helmet";

const Chat = () => {
  return (
    <Helmet>
      <meta name="robots" content="noimageindex, nofollow, nosnippet"></meta>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        crossOrigin=""
        src="//js-na1.hs-scripts.com/6034553.js"
      ></script>
    </Helmet>
  );
};

export default Chat;
